<template>
  <div class="report">
    <div class="chart">
      <h1>产品统计</h1>
      <!-- 检测样本产品统计 -->
      <div id="sample_name"></div>
    </div>

    <div class="chart">
      <!-- 样本日检测数量 -->
      <h1>
        样本检测数量统计&nbsp;&nbsp;共<span style="color: red"
          >{{ total }} </span
        >条数据
      </h1>
      <div id="sample_count"></div>
    </div>

    <div class="chart">
      <!-- 样本检测合格率 -->
      <h1>样本检测合格率</h1>
      <div id="sample_check_status"></div>
    </div>
  </div>
</template>

<script>
import { Chart, registerShape } from "@antv/g2";
import moment from "moment";
import axios from "@/utils/axios";
export default {
  name: "Report",
  data() {
    return {
      chart: null,
      data: [{ item: "暂无数据", count: 0, percent: 1 }],
      chart2: null,
      data2: [{ day: moment().format("YYYY-MM-DD"), value: 0 }],
      chart3: null,
      data3: [{ value: 10 }],
      total: 0,
    };
  },
  methods: {
    renderChart1() {
      const chart = new Chart({
        container: "sample_name",
        autoFit: true,
        height: 500,
      });

      chart.coordinate("theta", {
        radius: 0.75,
      });

      chart.data(this.data);

      chart.scale("percent", {
        formatter: (val) => {
          val = val * 100 + "%";
          return val;
        },
      });

      chart.tooltip({
        showTitle: false,
        showMarkers: false,
      });

      chart
        .interval()
        .position("percent")
        .color("item")
        .label("percent", {
          content: (data) => {
            return `${data.item}: ${data.count}`;
          },
        })
        .adjust("stack");

      chart.interaction("element-active");
      this.chart = chart;
      this.chart.render();
    },
    renderChart2() {
      const chart = new Chart({
        container: "sample_count",
        autoFit: true,
        height: 500,
      });

      chart.data(this.data2);
      chart.scale({
        year: {
          range: [0, 1],
        },
        value: {
          min: 0,
          nice: true,
        },
      });

      chart.tooltip({
        showCrosshairs: true, // 展示 Tooltip 辅助线
        shared: true,
      });

      chart.line().position("day*value").label("value");
      chart.point().position("day*value");

      this.chart2 = chart;
      this.chart2.render();
    },
    renderChart3() {
      // 自定义Shape 部分
      registerShape("point", "pointer", {
        draw(cfg, container) {
          const group = container.addGroup();
          const center = this.parsePoint({ x: 0, y: 0 }); // 获取极坐标系下画布中心点
          // 绘制指针
          group.addShape("line", {
            attrs: {
              x1: center.x,
              y1: center.y,
              x2: cfg.x,
              y2: cfg.y,
              stroke: cfg.color,
              lineWidth: 5,
              lineCap: "round",
            },
          });
          group.addShape("circle", {
            attrs: {
              x: center.x,
              y: center.y,
              r: 9.75,
              stroke: cfg.color,
              lineWidth: 4.5,
              fill: "#fff",
            },
          });

          return group;
        },
      });

      const chart = new Chart({
        container: "sample_check_status",
        autoFit: true,
        height: 500,
        padding: [0, 0, 30, 0],
      });
      chart.data(this.data3);
      chart.scale("value", {
        min: 1,
        max: 10,
        tickInterval: 1,
      });
      chart.coordinate("polar", {
        startAngle: (-9 / 8) * Math.PI,
        endAngle: (1 / 8) * Math.PI,
        radius: 0.75,
      });

      chart.axis("1", false);
      chart.axis("value", {
        line: null,
        label: {
          offset: -36,
          style: {
            fontSize: 18,
            textAlign: "center",
            textBaseline: "middle",
          },
        },
        subTickLine: {
          count: 4,
          length: -15,
        },
        tickLine: {
          length: -24,
        },
        grid: null,
      });
      chart.legend(false);
      chart
        .point()
        .position("value*1")
        .shape("pointer")
        .color("#1890FF")
        .animate({
          appear: {
            animation: "fade-in",
          },
        });

      // 绘制仪表盘背景
      chart.annotation().arc({
        top: false,
        start: [1, 1],
        end: [9, 1],
        style: {
          // 底灰色
          stroke: "#CBCBCB",
          lineWidth: 18,
          lineDash: null,
        },
      });

      // 绘制指标
      chart.annotation().arc({
        start: [1, 1],
        end: [this.data3[0].value, 1],
        style: {
          stroke: "#1890FF",
          lineWidth: 18,
          lineDash: null,
        },
      });
      // 绘制指标数字
      chart.annotation().text({
        position: ["50%", "85%"],
        content: "合格率",
        style: {
          fontSize: 20,
          fill: "#545454",
          textAlign: "center",
        },
      });
      chart.annotation().text({
        position: ["50%", "90%"],
        content: `${this.data3[0].value * 10} %`,
        style: {
          fontSize: 36,
          fill: "#545454",
          textAlign: "center",
        },
        offsetY: 15,
      });
      this.chart3 = chart;
      this.chart3.render();
    },
  },
  mounted() {
    axios.post("/stat/samplename").then((res) => {
      if (res.code === 200 && res.data && res.data.length > 0) {
        this.data = res.data;
      }
      this.renderChart1();
    });

    /* 样本检测数量 */
    axios.post("/stat/samplecount").then((res) => {
      if (res.code === 200 && res.data && res.data.length > 0) {
        this.data2 = res.data;
      }
      this.renderChart2();
    });

    axios.post("/stat/checkStatus").then((res) => {
      if (res.code === 200 && res.data) {
        this.data3[0].value = res.data;
      }
      this.renderChart3();
    });

    axios.post("/sample/total").then((res) => {
      if (res.code === 200 && res.data) {
        this.total = res.data;
      }
    });
  },
};
</script>

<style scoped lang="stylus">
#sample_name {
  width: 500px;
  margin-left: 40px;
}

#sample_count {
  width: 800px;
  margin-left: 80px;
}

#sample_check_status {
  width: 500px;
  margin-left: 80px;
}

.report {
  display: flex;
  flex-wrap: wrap;
}

.chart {
  margin-top: 20px;
}
</style>